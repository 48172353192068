import React, { useState } from 'react';
import './Navbar.css'; // Adjust path as needed

const Navbar = () => {
  const [showNavbar, setShowNavbar] = useState(false);

  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar);
  };

  // This function will be called when a menu item is clicked
  const handleMenuClick = () => {
    setShowNavbar(false); // Close the navbar after clicking a menu item
  };

  return (
    <nav className="navbar">
      <div className="container">
        <div className="logo">
          {/* Replace the SVG logo with a PNG image */}
          <img src="/logo.svg" alt="Logo" className="logo-img" />
        </div>
        <div className="menu-icon" onClick={handleShowNavbar}>
          <Hamburger />
        </div>
        <div className={`nav-elements ${showNavbar ? 'active' : ''}`}>
          <ul>
            <li><a href="#home" onClick={handleMenuClick}>Home</a></li>
            <li><a href="#services" onClick={handleMenuClick}>Services</a></li>
            <li><a href="#contact-us" onClick={handleMenuClick}>Contact Us</a></li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

const Hamburger = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="52"
    height="24"
    viewBox="0 0 52 24"
  >
    <g id="Group_9" data-name="Group 9" transform="translate(-294 -47)">
      <rect id="Rectangle_3" width="42" height="4" rx="2" transform="translate(304 47)" fill="#574c4c" />
      <rect id="Rectangle_5" width="42" height="4" rx="2" transform="translate(304 67)" fill="#574c4c" />
      <rect id="Rectangle_4" width="52" height="4" rx="2" transform="translate(294 57)" fill="#574c4c" />
    </g>
  </svg>
);

export default Navbar;