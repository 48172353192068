import React from 'react';
import Lottie from 'lottie-react';
import { motion } from 'framer-motion'; // Import Framer Motion
import heroAnimation from '../animations/computer-interface'; // Adjust the path if needed
import './Hero.css';

const fadeInUp = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0, transition: { duration: 1 } }
};

const Hero = () => {
  return (
    <div id="home" className="hero-container">
      <div className="container">
        <motion.div
          className="hero-container-content"
          initial="hidden"
          animate="visible"
          variants={fadeInUp}
        >
          <div className="hero-text">
            <motion.h1 className="hero-title" variants={fadeInUp}>
              Defy<span className="much-text">Much</span>
            </motion.h1>
            <motion.p className="hero-quote" variants={fadeInUp}>
              Redefining Marketing, Elevating Brands.
            </motion.p>
            <motion.p className="intro-text" variants={fadeInUp}>
            At DefyMuch, we believe in pushing the boundaries of marketing by blending creativity with functionality. Our approach centers on crafting unique UI/UX designs that are not only visually stunning but also practical, combined with cutting-edge programming and advanced data analysis. This powerful combination allows us to develop high-conversion campaigns tailored to meet the specific needs of each client, ensuring measurable results and impactful brand growth.
            </motion.p>
            <motion.div variants={fadeInUp} className="contact-button-wrapper">
              <a href="#contact-us" className="contact-button">Contact Us</a>
            </motion.div>
          </div>
          <motion.div className="hero-animation" variants={fadeInUp}>
            <Lottie animationData={heroAnimation} loop={true} />
          </motion.div>
        </motion.div>
      </div>
    </div>
  );
};

export default Hero;