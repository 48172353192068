import React, { useState, useEffect, useRef } from 'react';
import Lottie from 'lottie-react';
import smileyAnimation from '../animations/smiley.json'; // Path to your smiley animation
import successAnimation from '../animations/success.json'; // Path to your success animation
import miamiSkylineAnimation from '../animations/miami-skyline.json'; // Miami skyline animation
import emailjs from 'emailjs-com';
import { motion } from 'framer-motion'; // Import Framer Motion
import './ContactUs.css';

const ContactUs = () => {
  const form = useRef();
  const miamiSkylineLottieRef = useRef(); // Reference for Lottie instance
  const [isFormSubmitted, setIsFormSubmitted] = useState(false); // Track form submission

  // Intersection Observer to trigger the Lottie animation when it comes into view
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            miamiSkylineLottieRef.current.play(); // Play the Lottie animation when in view
          }
        });
      },
      {
        threshold: 0.5, // Trigger when 50% of the animation is in view
      }
    );

    const element = document.querySelector('.miami-skyline-animation');
    if (element) {
      observer.observe(element); // Start observing the element
    }

    return () => {
      if (element) observer.unobserve(element); // Clean up
    };
  }, []);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm(
      'service_kvhn68b',     // Replace with your actual service ID
      'template_c8z83xl',    // Replace with your actual template ID
      form.current,
      'wQBpNvRYItPAYGSN0'    // Use the Public Key here
    )
    .then((result) => {
        console.log(result.text);
        setIsFormSubmitted(true); // Hide form and show success message
    }, (error) => {
        console.log(error.text);
        alert('Failed to send message. Please try again.');
    });
  };

  return (
    <div id="contact-us" className="contact-us-container">
      <div className="container">
        <div className="contact-us-content">
          {/* Left Column */}
          <motion.div
            className="left-column"
            initial={{ opacity: 0, x: -100 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.8 }}
          >
            <div className="contact-heading">
              <h2>Let's talk</h2>
              <div className="smiley-animation">
                <Lottie animationData={smileyAnimation} loop={true} />
              </div>
            </div>
            <p className="contact-description">
              Whether you're looking to discuss your next project or simply want to connect, feel free to reach out. We look forward to the opportunity to collaborate and support your goals.
            </p>
            <p className="office-info">
              Headquarters in Miami with offices in Washington DC.
            </p>
            <div className="miami-skyline-animation">
              <Lottie
                lottieRef={miamiSkylineLottieRef} // Lottie instance ref
                animationData={miamiSkylineAnimation}
                loop={false}
                autoplay={false} // Disable autoplay, play when in view
              />
            </div>
          </motion.div>

          {/* Right Column with Fade-Up Animation */}
          <motion.div
            className="right-column"
            initial={{ opacity: 0, y: 100 }} // Fade-up animation
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.8 }}
          >
            {!isFormSubmitted ? (
              <form ref={form} onSubmit={sendEmail} className="contact-form">
                <label htmlFor="name">Name*</label>
                <input type="text" id="name" name="name" required />
                <label htmlFor="email">Email*</label>
                <input type="email" id="email" name="email" required />
                <label htmlFor="phone">Phone</label>
                <input type="tel" id="phone" name="phone" />
                <label htmlFor="message">Message</label>
                <textarea id="message" name="message"></textarea>
                <button type="submit" className="submit-button">Send Message</button>
              </form>
            ) : (
              <div className="success-message">
                <Lottie animationData={successAnimation} loop={false} /> {/* Set loop to false */}
                <p>Your message has been sent successfully!</p>
              </div>
            )}
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;