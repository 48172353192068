import React, { useEffect } from 'react';
import Lottie from 'lottie-react';
import { motion } from 'framer-motion'; // Import Framer Motion
import './Services.css';

// Import your Lottie animations here
import ppcAnimation from '../animations/ppc.json'; // Sample animation
import webDevAnimation from '../animations/web-dev.json'; // Sample animation
import socialMedia from '../animations/social-media.json'; // Sample animation
import contentCreation from '../animations/content-marketing.json'; // Sample animation
import AIsolutions from '../animations/ai.json'; // Sample animation
import dataAnalysis from '../animations/data-analysis.json'; // Sample animation
import branding from '../animations/branding.json'; // Sample animation
import consulting from '../animations/consultting.json'; // Sample animation
import pr from '../animations/pr.json'; // Sample animation

const servicesData = [
  {
    id: 1,
    name: 'PPC ADS',
    animation: ppcAnimation,
    description: 'Boost your traffic and conversions with targeted pay-per-click campaigns.',
  },
  {
    id: 2,
    name: 'Web/App Development',
    animation: webDevAnimation,
    description: 'Build fast, responsive websites and applications to meet modern digital demands.',
  },
  {
    id: 3,
    name: 'Social Media Optimization',
    animation: socialMedia,
    description: 'Optimize your social media presence to engage and grow your audience.',
  },
  {
    id: 4,
    name: 'Content Creation',
    animation: contentCreation,
    description: 'Create compelling content that resonates with your target audience.',
  },
  {
    id: 5,
    name: 'AI Solutions',
    animation: AIsolutions,
    description: 'Leverage AI to automate tasks and enhance decision-making.',
  },
  {
    id: 6,
    name: 'Data Analysis',
    animation: dataAnalysis,
    description: 'Turn data into actionable insights that drive business performance.',
  },
  {
    id: 7,
    name: 'Branding',
    animation: branding,
    description: 'Develop a unique and memorable brand identity to stand out.',
  },
  {
    id: 8,
    name: 'Consulting',
    animation: consulting,
    description: 'Expert advice to help your business navigate complex challenges.',
  },
  {
    id: 9,
    name: 'Public Relations',
    animation: pr,
    description: 'Manage your reputation and communicate effectively with your audience.',
  },
];

const Services = () => {
  return (
    <div id="services" className="services-container">
      <h2 className="services-heading">Discover all our Services</h2>
      <p className="services-description">
        At DefyMuch, we offer a comprehensive range of services designed to help your business grow and succeed. From digital marketing to web development and AI solutions, we tailor our offerings to meet your specific needs. Discover how our expertise can make a difference.
      </p>
      <div className="services-cards">
        {servicesData.map((service, index) => (
          <motion.div
            key={service.id}
            className="service-card"
            initial={{ opacity: 0, translateY: 50 }}
            whileInView={{ opacity: 1, translateY: 0 }}
            viewport={{ once: true, amount: 0.3 }} // Triggers animation when 30% of the card is in view
            transition={{ duration: 0.5, delay: index * 0.2 }} // Staggered animations
          >
            <div className="service-animation">
              <Lottie animationData={service.animation} loop={true} />
            </div>
            <h3 className="service-name">{service.name}</h3>
            <p className="service-description">{service.description}</p>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default Services;