import React from 'react';
import Hero from './components/Hero';
import Navbar from './components/Navbar'; // Adjust the path to your actual file structure
import Services from './components/Services'; // Make sure Services component path is correct
import ContactUs from './components/ContactUs';
import './App.css'; // Assuming you have global styles

function App() {
  return (
    <div>
      <Navbar />
      <Hero />
      <Services /> 
      <ContactUs />
    
    </div>
  );
}

export default App;